<template>
  <AgreeTncDialog
    :agreeTncVisible.sync="visible"
    :headerText="headerText"
    :accountData="accountData"
    @activateSuccess="activateSuccess"
  >
    <div class="header-text text-uppercase">{{ $t(headerText) }}</div>
    <ol>
      <li>{{ $t('promotion.cryptoCashback.dialog.tncContext.tnc1') }}</li>
      <li>{{ $t('promotion.cryptoCashback.dialog.tncContext.tnc1desc') }}</li>
      <li>{{ $t('promotion.cryptoCashback.dialog.tncContext.tnc2') }}</li>
      <li>
        <CashbackTable />
      </li>
      <li>{{ $t('promotion.cryptoCashback.dialog.tncContext.tnc3') }}</li>
      <li>{{ $t('promotion.cryptoCashback.dialog.tncContext.tnc4') }}</li>
      <li>{{ $t('promotion.cryptoCashback.dialog.tncContext.tnc5') }}</li>
      <li>{{ $t('promotion.cryptoCashback.dialog.tncContext.tnc5desc') }}</li>
      <li>{{ $t('promotion.cryptoCashback.dialog.tncContext.tnc6') }}</li>
    </ol>
  </AgreeTncDialog>
</template>

<script>
import AgreeTncDialog from '../common/AgreeTncDialog.vue';
import CashbackTable from './CashbackTable.vue';

export default {
  props: {
    agreeTncVisible: {
      type: Boolean,
      default: false
    },
    headerText: {
      type: String,
      default: ''
    },
    accountData: {
      type: Object,
      default: () => ({})
    }
  },
  components: { AgreeTncDialog, CashbackTable },
  data() {
    return {
      visible: this.agreeTncVisible
    };
  },
  watch: {
    agreeTncVisible(bool) {
      this.visible = bool;
    },
    visible(bool) {
      this.updateAgreeTncVisible(bool);
    }
  },
  methods: {
    activateSuccess() {
      this.$emit('activateSuccess');
    },
    updateAgreeTncVisible(bool) {
      this.$nextTick(() => {
        this.$emit('update:agreeTncVisible', bool);
      });
    }
  }
};
</script>
