<template>
  <GeneralDialog :visible.sync="isShowDialog" @close="onClose">
    <div class="dialog-content">
      <p>{{ $t('promotion.cryptoCashback.dialog.selectAccountContext') }}</p>
    </div>

    <el-form :model="formData" :rules="rules" ref="form">
      <SelectForm
        v-model="formData.accountMt4Dto"
        :label="$t('common.field.accName')"
        name="accountMt4Dto"
        class="l-select"
        popperClass="max-height-144"
      >
        <el-option v-for="(item, index) in accountOptions" :key="index" :value="item" :label="item.label"></el-option>
      </SelectForm>

      <div class="btn-wrapper">
        <el-button class="btn-blue submit-btn" @click="onSubmit">
          {{ $t('common.button.submit') }}
        </el-button>
      </div>
    </el-form>
  </GeneralDialog>
</template>

<script>
import GeneralDialog from '@/components/dialog/general/Dialog.vue';
import SelectForm from '@/components/form/Select';

export default {
  name: 'AcountDialog',
  components: { GeneralDialog, SelectForm },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    campaignID: {
      type: Number,
      required: true
    },
    accountOptions: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  data() {
    return {
      rules: {
        accountMt4Dto: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change'
          }
        ]
      },
      formData: {
        campaignId: this.campaignID,
        accountMt4Dto: null
      },
      isShowDialog: false
    };
  },
  watch: {
    dialogVisible: {
      immediate: true,
      handler(newValue) {
        this.isShowDialog = newValue;
      }
    },
    accountOptions: {
      immediate: true,
      handler(newValue) {
        if (newValue.length) this.formData.accountMt4Dto = newValue[0];
      }
    }
  },
  methods: {
    showError() {
      this.$message({
        message: this.$t('common.field.failed'),
        type: 'error',
        duration: 5000
      });
    },
    onClose() {
      this.$emit('close');
    },
    excuteValidate() {
      return new Promise(resolve => {
        this.$refs.form.validate(valid => {
          resolve(valid);
        });
      });
    },
    async onSubmit() {
      try {
        const isValid = await this.excuteValidate();
        if (!isValid) return;

        this.$emit('sendAccountMt4Dto', this.formData.accountMt4Dto);
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/promotion/cryptoCashback/accountDialog.scss';
</style>
