<template>
  <div class="table-container">
    <!-- table -->
    <el-row :gutter="20">
      <el-col :span="24">
        <div>
          <el-table :data="displayData" class="desc-table">
            <el-table-column min-width="180">
              <template slot="header">
                <div>
                  <div>
                    {{ $t('promotion.cryptoCashback.field.netDeposit') }}
                  </div>
                  <div>
                    {{ $t('promotion.cryptoCashback.field.usddesc') }}
                  </div>
                </div>
              </template>
              <template slot-scope="scope">
                {{ scope.row.currency | currencyToSymbol }}{{ scope.row.netDeposit }}
              </template>
            </el-table-column>
            <el-table-column min-width="180">
              <template slot="header">
                <div>
                  <div>
                    {{ $t('promotion.cryptoCashback.field.cashback') }}
                  </div>
                  <div>
                    /{{ $t('promotion.cryptoCashback.field.standardLot') }}
                  </div>
                </div>
              </template>
              <template slot-scope="scope">
                {{ scope.row.currency | currencyToSymbol }}{{ scope.row.cashback }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'CashbackTable',
  data() {
    return {
      displayData: [
        {
          currency: 'USD',
          netDeposit: 500,
          cashback: 0.5,
        },
        {
          currency: 'USD',
          netDeposit: 2000,
          cashback: 1,
        },
        {
          currency: 'USD',
          netDeposit: 5000,
          cashback: 1.5,
        },
        {
          currency: 'USD',
          netDeposit: 10000,
          cashback: 2,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/promotion/blueHeadTable.scss';
/deep/ .el-table {
  th {
    font-size: 12px;
  }
}

@media (min-width: 640px) {
  /deep/ .el-table {
    th {
      font-size: 15px;
    }
  }
}
</style>