<template>
  <div class="table-container">
    <!-- table title -->
    <div class="table-title blue-underline" :class="{ 'with-account': account }">
      {{ $t('promotion.cryptoCashback.depositHistory') }}
    </div>

    <div v-show="account" class="account-context">{{ account }}</div>

    <!-- table -->
    <el-row :gutter="20">
      <el-col :span="24">
        <div>
          <el-table :data="displayData" class="head-gutter">
            <el-table-column :label="$t('promotion.cryptoCashback.field.date')" min-width="200">
              <template slot-scope="scope">
                {{ scope.row.date }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('promotion.cryptoCashback.field.depositAmount')" min-width="200">
              <template slot-scope="scope"> {{ scope.row.depositAmount | formatNumber }}</template>
            </el-table-column>
            <el-table-column :label="$t('promotion.cryptoCashback.field.withdrawAmount')" min-width="200">
              <template slot-scope="scope">
                {{ scope.row.withdrawAmount | formatNumber }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('promotion.cryptoCashback.field.netDepositAmount')" min-width="200">
              <template slot-scope="scope">{{ scope.row.netDepositAmount | formatNumber }}</template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination';

export default {
  name: 'DepositHistory',
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    account: {
      type: String,
      default: ''
    }
  },
  components: { Pagination },
  computed: {
    displayData() {
      return [...this.tableData];
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/promotion/blueHeadTable.scss';
.table-title.with-account {
  margin-bottom: 16px;
}
.account-context {
  margin-top: 4px;
  margin-bottom: 16px;
}
</style>
