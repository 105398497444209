<template>
  <ConditionDialog
    :visible.sync="open"
    :isSuccess="cbType === 'success'"
    :dialogHeightAuto="cbType === 'failNotOptout'"
    @closCbDialog="closCbDialog"
  >
    <template #content>
      <p v-if="cbType === 'success'" class="text-congratuate">
        {{ $t('promotion.cryptoCashback.message.congratulate') }}
      </p>
      <i18n class="text-result" :path="getI18nContextPath()" tag="p" />
      <el-button v-if="cbType === 'failNotOptout'" class="btn-blue submit-btn" @click="sureToOptOut">
        {{ $t('common.button.confirm') }}
      </el-button>
    </template>
  </ConditionDialog>
</template>

<script>
import ConditionDialog from '@/components/dialog/ConditionDialog.vue';

export default {
  name: 'CallbackDialog',
  components: {
    ConditionDialog
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    callbackType: {
      type: String,
      require: true // success, failNotOptout, failOptout
    }
  },
  data() {
    return {
      open: false,
      cbType: null
    };
  },
  watch: {
    show(val) {
      this.open = val;
    },
    open(bool) {
      this.handleClose(bool);
    },
    callbackType: {
      immediate: true,
      handler(newValue) {
        this.cbType = newValue;
      }
    }
  },
  methods: {
    getI18nContextPath() {
      switch (this.cbType) {
        case 'success':
          return `promotion.cryptoCashback.message.congratulateContext`;
        case 'failNotOptout':
          return `promotion.cryptoCashback.message.signedOutPromo`;
        case 'failOptout':
          return `promotion.cryptoCashback.message.deactivePromo`;
        default:
          return '';
      }
    },
    handleClose(bool) {
      this.$nextTick(() => {
        this.$emit('update:show', bool);
      });
    },
    sureToOptOut() {
      this.$emit('optout', true);
    },
    closCbDialog() {
      this.$emit('closCbDialog');
    }
  }
};
</script>
