<template>
  <div class="table-container">
    <!-- table title -->
    <div class="table-title blue-underline">
      {{ $t('promotion.cryptoCashback.tradingRewardHistory') }}
    </div>

    <!-- table -->
    <el-row :gutter="20">
      <el-col :span="24">
        <div>
          <el-table :data="displayData" class="head-gutter">
            <el-table-column prop="date" :label="$t('promotion.cryptoCashback.field.tradingDate')" min-width="200">
              <template slot-scope="scope">
                {{ scope.row.tradingDate }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('promotion.cryptoCashback.field.tradingLot')" min-width="200">
              <template slot-scope="scope"> {{ scope.row.tradingLot | formatNumber }}</template>
            </el-table-column>
            <el-table-column prop="amount" :label="$t('promotion.cryptoCashback.field.cashRebate')" min-width="200">
              <template slot-scope="scope">
                {{ scope.row.cashRebate | formatNumber }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'TradingRewardHistory',
  props: {
    tableData: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    displayData() {
      return [...this.tableData];
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/promotion/blueHeadTable.scss';
</style>
