<template>
  <section id="cryptoCashback">
    <!-- banner -->
    <div class="banner-container">
      <div
        class="new-banner-image"
        :style="{ backgroundImage: `url(${rwdBannerBg})`, transform: imgLang === 'ar' ? `rotateY(180deg)` : '' }"
      >
        <BannerItemV1 v-bind="banner" :lang="imgLang"></BannerItemV1>
      </div>
    </div>

    <!-- main -->
    <div class="content-box">
      <div class="inner">
        <div class="inner-container">
          <FundAccount
            :optIn="optIn"
            :campaignID="campaignID"
            :participantStatus="participantStatus"
            @activeOptIn="onActivate"
            @deactivateAndQueryData="onDeactivate"
          />

          <!-- tables -->
          <div class="table-wrapper">
            <div class="table-item">
              <DepositHistory :tableData="depositHistoryData" :account="participateAccount" />
            </div>
            <div class="table-item">
              <TradingRewardHistory :tableData="tradingRewardHistoryData" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import FundAccount from '@/components/promotion/cryptoCashback/FundAccount.vue';
import DepositHistory from '@/components/promotion/cryptoCashback/DepositHistory.vue';
import TradingRewardHistory from '@/components/promotion/cryptoCashback/TradingRewardHistory.vue';
import BannerItemV1 from '@/components/banner/item/v1/BannerItem.vue';
import { PROMOTION_TYPE } from '@/constants/promotion';
import { isSuccess } from '@/util/restApi';
import { apiCampaignInfo, apiCashbackTradingRewardHistories, apiCampaignOptStatus } from '@/resource';

export default {
  name: 'CryptoCashback',
  components: {
    FundAccount,
    DepositHistory,
    TradingRewardHistory,
    BannerItemV1
  },
  data() {
    return {
      optIn: true, // once user opt-in, the api will return true forever
      participantStatus: 'OO', // FE mainly focus on 'S', 'EP', 'X' & 'OO', main logic please refer to src\components\promotion\cryptoCashback\FundAccount.vue
      depositHistoryData: [],
      tradingRewardHistoryData: [],
      participateAccount: '',
      windowWidth: window.innerWidth,
      bannerPromo: [31],
      banner: null
    };
  },
  computed: {
    campaignID() {
      let campaign =
        this.$store.state.promotion.eligibleCampaigns.find(c => c.campaignType === PROMOTION_TYPE.CASHBACK) || {};
      if (!campaign.id) {
        this.$router.push('/404');
        return null;
      }
      return campaign.id;
    },
    imgLang() {
      return this.$store.state.common.lang;
    },
    rwdBannerBg() {
      if (this.banner?.img) {
        if (this.windowWidth >= 2048) {
          return this.banner?.imgLarge;
        } else if (this.windowWidth <= 414) {
          return this.banner?.imgSmall;
        } else {
          return this.banner?.img;
        }
      }

      return null;
    }
  },
  mounted() {
    this.init();
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    init() {
      this.getOptStatus();
      this.getDepositHistory();
      this.getTradingHistories();
      this.getBannerSetting();
    },
    getBannerSetting() {
      const regulatorConfig = this.$config.banner(this.regulator).filter(el => el.promotionId === this.campaignID)[0];

      if (this.bannerPromo.includes(this.campaignID) && Object.keys(regulatorConfig.info?.secondBanner).length) {
        this.banner = { ...regulatorConfig.info.secondBanner };
      }
    },
    getOptStatus() {
      apiCampaignOptStatus(this.campaignID)
        .then(res => {
          const { data: result } = res;
          if (isSuccess(result)) {
            this.optIn = result.data;
          }
        })
        .catch(e => console.log(e));
    },
    getDepositHistory() {
      apiCampaignInfo(this.campaignID)
        .then(res => {
          const { data: result } = res;
          if (isSuccess(result)) {
            const { histories, participantStatus, accountMt4Dto } = result.data;
            if (histories?.length) {
              this.depositHistoryData = [...histories].map(item => ({
                ...item,
                formatDate: this.$options.filters.dateYearLast(item.date)
              }));
            }
            this.participantStatus = participantStatus || null;

            if (accountMt4Dto) {
              const { mt4Account, currency, accountDisplayType } = accountMt4Dto;
              let accountType = this.$config.accountTypeMaps[Number(accountDisplayType)] || null;
              this.participateAccount = accountType
                ? `${mt4Account} - ${accountType} - ${currency}`
                : `${mt4Account} - ${currency}`;
            }
          }
        })
        .catch(e => {
          console.log(e);
        });
    },
    getTradingHistories() {
      apiCashbackTradingRewardHistories(this.campaignID)
        .then(res => {
          const { data: result } = res;
          if (isSuccess(result)) {
            const { histories } = result.data;
            if (histories?.length) {
              this.tradingRewardHistoryData = [...histories].map(item => ({
                ...item,
                formatDate: this.$options.filters.dateYearLast(item.tradingDate)
              }));
            }
          }
        })
        .catch(e => console.log(e));
    },
    onActivate() {
      this.init();
    },
    onDeactivate(optStatus) {
      if (optStatus === 'failOptout') {
        this.init();
        this.optIn = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/cryptoCashback.scss';
</style>
